import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PageHeader from 'components/PageHeader';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import image1 from 'images/project-2a.png';
import image2 from 'images/project-2b.png';
import image3 from 'images/project-2c.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Space for a Growing Family"
      meta={[
        {
          name: 'description',
          content: 'Home expansion provides much-needed space for a family of nine.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Space for a Growing Family"
      intro="Home expansion provides much-needed space for a family of nine."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            Some time ago, my husband and I went from just the two of us to a family of nine, with
            the arrival of several foster children. Our little three-bedroom house was bursting at
            the seams, so my mother-in-law offered to swap her home and the nine of us moved into
            our current property, which had four bedrooms and one bathroom. When the children were
            small we all managed to fit in, however, our growing teens, for whom we had been made
            guardians&ndash; kept growing! Unfortunately, our property could not keep up with the
            demands of family life and it quickly became mouldy and damp.
          </Paragraph>
          <Paragraph>
            Around this time, we were introduced to the Ryhaven Trust. They looked at different ways
            of improving the property and agreed on a plan to extend the house by adding four
            bedrooms and transforming one into a laundry, toilet and shower room. They also noticed
            the chimney had detached from the house (due to the 2016 earthquake) and quickly began
            work began to replace it with a safe chimney and fire box, which now keeps us warm
            during the winter months!
          </Paragraph>
          <Paragraph>
            What&rsquo;s more, is that we had severely damaged drains throughout the property, which
            were leaking sewage into the ground. They were all replaced. The old electrics in the
            house were addressed too and I don&rsquo;t even want to think about what could have
            happened if the trust hadn&rsquo;t spotted these hazards.
          </Paragraph>
          <Paragraph>
            The Ryhaven team were brilliant and worked so quickly that four months later, each child
            had their own room. The regular meetings and daily communication made the process
            empowering and allowed us to be involved in problem solving and decision making. The
            extension has meant that my children, who all have varying degrees of high and complex
            needs, have been able to have their own quiet space to develop their own identities.
            Needless to say, everyone is sleeping much better!
          </Paragraph>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
